import React, {useState, useEffect, useRef} from 'react';
import Axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';
import HeaderInner from '../../components/HeaderInner'
import Footer from '../../components/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faSpinner, faCamera } from '@fortawesome/free-solid-svg-icons';

function SendVideo() {

  const [text, setText] = useState([]);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const { handleSubmit } = useForm();

  function maxSelectFile(event) {
    let files = event.target.files // create file object
        if (files.length > 1) { 
           const msg = 'Você só pode enviar 1 memória por vez'
           event.target.value = null // discard selected file
           console.log(msg)
          return false;
  
      }
    return true;
  }

  function checkFileSize(event) {
    setIsLoading(true);
    let files = event.target.files
    let size = 1024 * 1024 * 20; 
    let err = ""; 
    for(var x = 0; x<files.length; x++) {
    if (files[x].size > size) {
      err += files[x].name+' é muito pesado, por favor envie um arquivo mais leve, com no máximo 20mb.\n';
    }
  };
  if (err !== '') {
      event.target.value = null
      console.log(err)
      setIsLoading(false);
      toast.error(err)
      return false
  }
  return true;
  }

  function checkMimeType(event) {
    setIsLoading(true);
    let files = event.target.files 
    let err = ''
    const types = ['video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime']
    for(var x = 0; x<files.length; x++) {
         if (types.every(type => files[x].type !== type)) {
         err += files[x].type+' não é um formato suportado.\n Escolha um arquivo em formato de vídeo.';
       }
    };
    if (err !== '') { // if message not same old that mean has error 
        event.target.value = null // discard selected file
        console.log(err)
        setIsLoading(false);
        toast.error(err)
        return false; 
    }
    return true;
  }

  function onFileUpload(e) {

    setIsLoading(true);
    
    try{
    
      let axiosConfig = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      const data = new FormData()
      data.append('video', e)
      data.append('text', text)

      Axios.post( process.env.REACT_APP_API_BASE_URL + "/video/new_video", data, axiosConfig)
      .then((res) => {
        setIsLoading(false);
        history.push("/depoimentos?word=" + text);
        //window.location.reload();
      });

    }
    catch (error) {
      console.log(error.response.data.error);
      setIsLoading(false);
      toast.error('😲' + error.response.data.error)
    };

  };

  function onChangeUserFile(e) {
    console.log(e.target.files[0])
    console.log(e.target.files[0].type)

    var files = e.target.files;

    if(checkFileSize(e) && checkMimeType(e)){ 
      setFile(e.target.files[0]);
      onFileUpload(e.target.files[0]);
    }
    
  }

  const inputFile = useRef(null);

  useEffect(() => {

  });

  return(

    <>

      <HeaderInner />

      <section className="sendvideo">

      <ToastContainer />

        <div className="content">

          <h1><strong>Grave um vídeo</strong><br/> contando em uma palavra o que significa o Câncer para você.</h1>

          <form encType="multipart/form-data" onSubmit={handleSubmit(onFileUpload)} id="newvideo">
            <input type="text" name="text" id="text" placeholder="Escreva a palavra aqui" onChange={e => setText(e.target.value.toUpperCase())} />

            {isLoading ? (
                <label htmlFor='video' className="disable"><FontAwesomeIcon icon={faSpinner} /> Enviando</label>
            ) : <label htmlFor='video'><FontAwesomeIcon icon={faVideo} /> Envie seu vídeo</label> }

            {/* <input type="file" name="video" id="video" placeholder="Envie seu video" ref={inputFile} onChange={e => onFileUpload(e.target.files[0]) } /> */}
            <input type="file" name="video" id="video" placeholder="Envie seu vídeo" ref={inputFile} onChange={onChangeUserFile} />
            {/* <button>Enviar</button> */}
          
          </form>

        </div>

      </section>

      <Footer />

    </>

  );

}

export default SendVideo;